@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0px;
	box-sizing: border-box;

}

body {
  background-color: #ffffff;
  padding-top: 1%;
}

	html, body {
		height: 100%;
		margin: 0;
	}

	#root {
		display: flex;
		flex-direction: column;
		min-height: 100%;
	}
.flex-grow {
  flex-grow: 1;
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.mainContentContainer{
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}