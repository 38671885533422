.namePhoto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.namePhoto img{
    width: 150px;
    height: auto;
    object-fit: cover;
    border-radius: 10%;
}

.photoContainer{
    display: flex;

}

@media (max-width: 768px){
    .photoContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}
