.contentContainer{
  min-height: 100vh;
  flex: 1 0 auto;
}

@media (max-width: 768px){
  .contactImg{
    width: 100%;
  }
}
