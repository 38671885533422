
.hero-image {
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 500px;
    z-index: 0;
}


.hero-text {
    font-size: x-large;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -45%);
    color: #ffffff;
    text-align: left;
    z-index: 1;
    max-width: 100vw;
    overflow-wrap: break-word;
}

@media (min-width: 688px) and (min-height: 1031px) {
    .hero-text {
        width: 85vw;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (max-width: 767px) {
    .hero-text {
        width: 85vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 768px) {
    .hero-text {
        width: 85vw;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 800px) {
    .hero-text {
        width: 85vw;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@media (min-width: 900px) and (max-height: 601px) {
    .hero-text {
        width: 85vw;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 1024px) and (min-height: 768px) {
    .hero-text {
        width: 85vw;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}


@media (min-width: 1024px) and (min-height: 1200px) {
    .hero-text {
        width: 85vw;
        top: 32%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@media (min-width: 1224px) and (min-height: 950px) {
    .hero-text {
        width: 85vw;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 1400px) and (min-height: 900px) {
    .hero-text {
        width: 85vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 1800px) and (min-height: 1000px) {
    .hero-text {
        width: 85vw;
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 2000px) {
    .hero-text {
        font-size: large;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        h1 { font-size: 4rem; }
        p { font-size: 2rem; }
    }
}

@media (min-width: 3000px) {
    .hero-text {
        font-size: large;
        top: 24%;
        left: 50%;
        transform: translate(-50%, -50%);
        h1 { font-size: 4rem; }
        p { font-size: 2rem; }
    }
}

@media (min-width: 4500px) {
    .hero-text {
        font-size: large;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -50%);
        h1 { font-size: 4rem; }
        p { font-size: 2rem; }
    }
}


@media (max-width: 400px) {
    .hero-text {
        top: 55%;
    }
}

@media (max-width: 376px) {
    .hero-text {
        top: 57%;
    }
}

@media (max-width: 350px) {
    .hero-text {
        top: 70%;
    }
}

@media (max-width: 320px) {
    .hero-text {
        top: 72%;
    }
}

@media (max-width: 250px) {
    .hero-text {
        top: 125%;
    }
}

/* Landscape orientation adjustments */
@media only screen and (max-width: 896px) and (orientation: landscape) {
    .hero-text {
        top: 78%;
        width: 70vw;
    }
}


@media only screen and (max-width: 767px) and (orientation: landscape) {
    .hero-text {
        top: 90%;
    }
}

@media only screen and (max-width: 600px) and (orientation: landscape) {
    .hero-text {
        top: 90%;
    }
}
@media only screen and (max-width: 580px) and (orientation: landscape) {
    .hero-text {
        top: 99%;
    }
}

@media (min-width: 950px) and (max-width: 1050px) and (max-height: 700px) {
    .hero-text {
        width: 85vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 1024px) and (max-width: 1223px) and (min-height: 1200px) and (max-height: 1925px) {
    .hero-text {
        width: 85vw;
        top: 22%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@media (min-width: 1024px) and (max-width: 1024px) and (min-height: 1200px) and (max-height: 1925px) {
    .hero-text {
        width: 85vw;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@media (min-width: 1400px) and (max-width: 1800px) and (min-height: 2000px) and (max-height: 2560px) {
    .hero-text {
        width: 85vw;
        top: 19%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@media (min-width: 1801px) and (max-width: 2200px) and (min-height: 2500px) and (max-height: 3900px) {
    .hero-text {
        width: 85vw;
        top: 12%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
