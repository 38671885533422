.individualCase p{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 12px;
}


.slick-dots{
  white-space: nowrap;
  margin-bottom: 5px;

}

@media (max-width: 1024px) {
  .sliderContainer{

    width: 90%;
    margin-bottom: 20px;
    border: none;
  }
  .individualCase p{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;

  }
}

@media (max-width: 680px) {
  .sliderContainer{
    width: 90%;
    margin-bottom: 20px;
    border: none;
  }
  .individualCase p{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 20px;

  }


}
@media (max-width: 560px) {
  .sliderContainer{
    width: 90%;
    margin-bottom: 20px;
    border: none;
  }
  .individualCase p{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;

  }


}
@media (max-width: 480px) {
  .sliderContainer{

    width: 90%;
    margin-bottom: 20px;
    border: none;
  }
  .individualCase p{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;

  }


}
@media (max-width: 380px) {
  .sliderContainer{

    width: 90%;
    margin-bottom: 20px;
    border: none;
  }
  .individualCase p{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;

  }


}
@media (max-width: 340px) {
  .sliderContainer{

    width: 90%;
    margin-bottom: 20px;
    border: none;
  }
  .individualCase p{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;

  }


}
