body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 15px;
    align-items: stretch;
    justify-items: center;
    justify-content: center;

}


.grid-item {
    padding: 10px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.banner-motion {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-item h2 {
    font-weight: bold;
}

.header {
    text-align: center;
    padding: 10px;
    font-weight: 600;
}

.practice-areas {
    padding: 10px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.text-box h2 {
    text-align: justify;
    padding: 0px;
    font-weight: 600;
}

.practice-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}


.practice-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-weight: 500;
    background-color: black;
    color: white;
}

.footer {
    background-color: #f2f2f2;
    padding: 10px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.practice-image-text {
    display: flex;
    justify-content:flex-end;
    align-items: center;
  }
