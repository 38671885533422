.footer-link {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.footer-link:hover {
    color: gray;
}

.footer {
    width: 100%;
    flex-shrink: 0;
    z-index: 0;
}
