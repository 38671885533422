.banner {
    position: relative;
    width: 100%;
    height: 100px; /* Adjust this to make the banner as skinny as you want */
}

.banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* Adjust color as needed */
    text-align: center;
    font-size: 2rem; /* Adjust font size as needed */
}