
.aboutContainer {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    margin: auto;

}

.about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1em;
    line-height: 1.4;
    padding: 1rem;
    text-align: justify;
}

.aboutImageContainer {
    width: 100%;
}

.about-image {
    width: 100%;
    height: auto;

}

@media (min-width: 1100px) {
    .aboutContainer {
        grid-template-columns: 1fr 1fr;
    }
    .about-image{
        border-radius: 1rem;

    }
    .aboutImageContainer {
        width: 100%;

        margin: 1rem;
    }
    .aboutImageContainer {
        width: auto;
    }
}

@media (min-width: 360px) {
    .about-image{
        border-radius: 1rem;

    }
    .aboutImageContainer {
        width: 100%;

        margin: 1rem;
    }
    .aboutImageContainer {
        width: auto;
    }
}