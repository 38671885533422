.menu a, .hamburger, .exit-button {
    cursor: pointer;
}

.img {
    max-width: 100%;
    height: auto;
    justify-content: center;
}

.logo-container {
    display: flex;
    justify-content: center;
}
nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 5%;
  }

  .navbar-menu-container {
    display: none;
  }

  .navbar-links-container a {
    margin-right: 3rem;
    text-decoration: none;
    color: #3b3738;
    font-size: 1.1rem;
    font-weight: 600;
  }

.menu {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 0;
    background: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    margin-right: 2rem;
}

.menu.show {
    width: 100%;
}

.exit-button {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 36px;
    margin-left: 50px;
    border: none;
    background: none;
    color: black;
}

.menu a {
    color: #3b3738;
    padding: 10px;
    text-decoration: none;

}

.menu a:hover {
    color: #4077bb;
}


@media (max-width: 768px){
    .menu{
        margin-right: 0;

    }
    .hamburger{
        margin-right: 1rem;
    }
}

@media (min-width: 768px) {
    .hamburger {
        display: none;

    }

   .logo-container {
       justify-content: flex-start;
   }

    .menu {
        position: relative;
        background: none;
        width: auto;
        overflow-x: visible;
        padding-top: 0;
        flex-direction: row;
    }

    .exit-button {
        display: none;
    }
}

@media(min-width: 414px){
.logo-container {
    margin-left: 3rem;
}
}

@media(min-width: 375px){
    .logo-container {
        margin-left: 1rem;
    }
}