.topContainer{
  display: flex !important;
  position: relative !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  font-size: small;
  margin-bottom:1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: .5rem;
  width: 100%;
  /* border-bottom: .5px solid #3b3738; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.container2{
  display: flex;
  justify-content: right;
}
.faxNum{
  white-space: nowrap;
}

button a:hover{
  color: #4077bb;
  background-color: #f2f2f2;
}